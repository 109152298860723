import React from 'react';
import { Route } from 'react-router-dom';
import { DOMAIN, CLIENT_ID, REDIRECT_URI } from './config/global';

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={props => (
      (
        localStorage.getItem('access_token') != null &&
        localStorage.getItem('expires_in') != null &&
        localStorage.getItem('id_token') != null &&
        localStorage.getItem('refresh_token') != null &&
        localStorage.getItem('token_type') != null &&
        localStorage.getItem('sub') != null &&
        localStorage.getItem('email') != null &&
        localStorage.getItem('username') != null
      ) ? (
          <Component {...props} />
        ) : (
          window.location = `${DOMAIN}/login?client_id=${CLIENT_ID}&response_type=code&scope=email+openid+phone+profile&redirect_uri=${REDIRECT_URI}`
        )
    )} />
  );
}

export default PrivateRoute;
