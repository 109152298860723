import { LOGIN, LOGOUT, SET_INFO, LOADING, SET_ICAO } from '../types/global';

const defaultState = {
  access_token: null,
  expires_in: null,
  id_token: null,
  refresh_token: null,
  token_type: null,
  sub: null,
  email: null,
  username: null,
  icao: null,
  loading: false,
  permissions: []
};

const global = (state = defaultState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        access_token: action.result.access_token,
        expires_in: parseInt(action.result.expires_in),
        id_token: action.result.id_token,
        refresh_token: action.result.refresh_token,
        token_type: action.result.token_type
      };
    case LOGOUT:
      return {
        ...state,
        access_token: null,
        expires_in: null,
        id_token: null,
        refresh_token: null,
        token_type: null,
        sub: null,
        email: null,
        username: null,
        permissions: [],
      };
    case SET_INFO:
      return {
        ...state,
        sub: action.result.sub,
        email: action.result.email,
        username: action.result.username,
        permissions: action.result.permissions,
      };
    case SET_ICAO:
      return {
        ...state,
        icao: action.icao,
      };
    case LOADING:
      return {
        ...state,
        loading: action.loading,
      }
    default:
      return state;
  }
}

export default global