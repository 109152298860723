import { LOGIN, LOGOUT, SET_INFO, LOADING, SET_ICAO, LOGIN_OAUTH } from '../types/global';
import { DOMAIN, CLIENT_ID, CLIENT_SECRET, SKYWISE_OAUTH, OAUTH_CLIENT_ID, OAUTH_CLIENT_SECRET} from '../config/global';
import axios from 'axios';
import queryString from 'query-string';

export function loginAction(result) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      localStorage.setItem('access_token', result.access_token);
      localStorage.setItem('expires_in', result.expires_in);
      localStorage.setItem('id_token', result.id_token);
      localStorage.setItem('refresh_token', result.refresh_token);
      localStorage.setItem('token_type', result.token_type);
      dispatch({
        type: LOGIN,
        result,
      });
      return resolve(result);
    });
  };
}

export function loginActionOAuth(result) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      localStorage.setItem('oauth_access_token', result.oauth_access_token);
      localStorage.setItem('oauth_expires_in', result.oauth_expires_in);
      localStorage.setItem('oauth_refresh_token', result.oauth_refresh_token);
      dispatch({
        type: LOGIN_OAUTH,
        result,
      });
      return resolve(result);
    });
  };
}

export function checkTokenActionOAuth() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      let expires_in_input = parseInt(localStorage.getItem('oauth_expires_in'));
      let refresh_token = localStorage.getItem('oauth_refresh_token');
      if (expires_in_input + 60 <= Date.now()) {
        return axios({
          method: 'post',
          url: `${SKYWISE_OAUTH}/token`,
          headers: { 
            'Content-Type':'application/x-www-form-urlencoded;charset=utf-8'
          },
          data: queryString.stringify({
            grant_type: "refresh_token",
            client_id: OAUTH_CLIENT_ID,
            client_secret: OAUTH_CLIENT_SECRET,
            refresh_token: refresh_token
          })
        }).then((result) => {
          let { access_token, expires_in, refresh_token } = result.data;
          expires_in = expires_in * 1000 + Date.now();
          localStorage.setItem('oauth_access_token', access_token);
          localStorage.setItem('oauth_expires_in', expires_in);
          localStorage.setItem('oauth_refresh_token', refresh_token);
          dispatch({
            type: LOGIN_OAUTH,
            result: {
              access_token,
              expires_in,
              refresh_token
            },
          });
          return resolve(true);
        }).catch((error) => {
          localStorage.clear();
          dispatch({
            type: LOGIN_OAUTH,
          });
          window.location = '/SDE/ExportDS';
          return reject(error);
        });
      } else {
        return resolve(false);
      }
    });
  };
}

export function checkTokenAction() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      let expires_in_input = parseInt(localStorage.getItem('expires_in'));
      let refresh_token = localStorage.getItem('refresh_token');
      if (expires_in_input + 60 <= Date.now()) {
        return axios({
          method: 'post',
          url: `${DOMAIN}/oauth2/token`,
          data: queryString.stringify({
            grant_type: "refresh_token",
            client_id: CLIENT_ID,
            refresh_token: refresh_token
          }),
          auth: {
            username: CLIENT_ID,
            password: CLIENT_SECRET,
          },
        }).then((result) => {
          let { access_token, expires_in, id_token, token_type } = result.data;
          expires_in = expires_in * 1000 + Date.now();
          localStorage.setItem('access_token', access_token);
          localStorage.setItem('expires_in', expires_in);
          localStorage.setItem('id_token', id_token);
          localStorage.setItem('token_type', token_type);
          localStorage.setItem('refresh_token', refresh_token);
          dispatch({
            type: LOGIN,
            result: {
              access_token,
              expires_in,
              id_token,
              refresh_token,
              token_type
            },
          });
          return resolve(true);
        }).catch((error) => {
          localStorage.clear();
          dispatch({
            type: LOGOUT,
          });
          window.location = '/SDE/ExportDS';
          return reject(error);
        });
      } else {
        return resolve(false);
      }
    });
  };
}

export function setICAOAction(icao) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (icao == null) {
        localStorage.removeItem('icao');
      } else {
        localStorage.setItem('icao', icao);
      }
      dispatch({
        type: SET_ICAO,
        icao,
      });
      return resolve(icao);
    });
  };
}

export function setInfoAction(result) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      localStorage.setItem('sub', result.sub);
      localStorage.setItem('email', result.email);
      localStorage.setItem('username', result.username);
      localStorage.setItem('permissions', JSON.stringify(result.permissions));
      dispatch({
        type: SET_INFO,
        result,
      });
      return resolve(result);
    });
  };
}

export function logoutAction() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      localStorage.clear();
      dispatch({
        type: LOGOUT,
      });
      window.location = '/';
      return resolve();
    });
  };
}

export function logoutActionOAuth() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      localStorage.removeItem('oauth_access_token');
      localStorage.removeItem('oauth_expires_in');
      localStorage.removeItem('oauth_refresh_token');
      dispatch({
        type: LOGIN_OAUTH,
      });
      window.location = '/SDE/ExportDS';
      return resolve();
    });
  };
}

export function toggleLoadingAction(loading) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: LOADING,
        loading
      });
      return resolve();
    });
  };
}