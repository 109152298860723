let skywise_oauth = 'https://core.skywise.com/foundry-multipass/api/oauth2';
let client_id = '7vv64nmgubv09k0kd1qjqd6r1b';
let client_secret ='asdpedqe7nrp2kngr34ll8cao2rrb7r0brmf3nu1t6n44mtamto';
let redirect_uri ='https://data.fomax.skywise.com';
let api_domain ='https://m2b7wzo6uc.execute-api.eu-west-1.amazonaws.com/prod';
let sas_url= 'https://m2b7wzo6uc.execute-api.eu-west-1.amazonaws.com/prod';
let domain = 'https://airbus-prod-10916-2j17.auth.eu-west-1.amazoncognito.com';
let dataset_path ='https://core.skywise.com/workspace/data-integration/dataset/preview';
let sas_env = 'PROD';
let s3_private = 'https://d3qv9yb1nf82id.cloudfront.net/private';
let x_api_key = 'zAiSOBF0iJ1TUKZHQiAeF923DEZgVEi641wjs46w';
let sas_x_api_key = 'zAiSOBF0iJ1TUKZHQiAeF923DEZgVEi641wjs46w';
let oauth_client_id = '75e09e73727d946d0fa5b90780ea1ba6'; 
let oauth_client_secret = 'ab5e47bd90fa399118b7bffc698b6a24';
let oauth_redirect_url ='https://data.fomax.skywise.com/SDE/ExportDS';
export const DOMAIN = domain;
export const CLIENT_ID = client_id;
export const CLIENT_SECRET = client_secret;
export const REDIRECT_URI = redirect_uri;
export const API_DOMAIN = api_domain;
export const SAS_URL = sas_url;
export const DATASET_PATH = dataset_path;
export const SAS_ENV = sas_env;
export const S3_PRIVATE = s3_private;
export const X_API_KEY = x_api_key;
export const SAS_X_API_KEY = sas_x_api_key;
export const SKYWISE_OAUTH = skywise_oauth;
export const OAUTH_REDIRECT_URI = oauth_redirect_url;
export const OAUTH_CLIENT_ID = oauth_client_id;
export const OAUTH_CLIENT_SECRET = oauth_client_secret;
