/* eslint-disable import/first */
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { Layout, Menu, Button, Icon, BackTop, Spin, Dropdown, Modal, Radio, message } from 'antd';
const { Content, Sider, Header } = Layout;
import { connect } from 'react-redux';
import PrivateRoute from './PrivateRoute';
import Root from './components/Root';
import Home from './components/Home';
import MDE from './components/MDE';
import SecurityLog from './components/SecurityLog';
import './App.css';
import { loginAction, logoutAction, setInfoAction, setICAOAction } from './actions/global';

class App extends React.Component {
  state = {
    // collapsed: false,
    showModalICAO: false,
    icaoList: [],
    icao: null,
    showDSLink: false,
    showSLLink: false,
  };

  componentWillMount() {
    const access_token = localStorage.getItem('access_token');
    const expires_in = localStorage.getItem('expires_in');
    const id_token = localStorage.getItem('id_token');
    const refresh_token = localStorage.getItem('refresh_token');
    const token_type = localStorage.getItem('token_type');
    const sub = localStorage.getItem('sub');
    const email = localStorage.getItem('email');
    const username = localStorage.getItem('username');
    const permissions = localStorage.getItem('permissions');
    const icao = localStorage.getItem('icao');
    if (
      access_token != null &&
      expires_in != null &&
      id_token != null &&
      refresh_token != null &&
      token_type != null &&
      permissions != null
    ) {
      let permissionsJson = JSON.parse(permissions);
      this.props.loginAction({
        access_token,
        expires_in,
        id_token,
        refresh_token,
        token_type
      });
      this.props.setInfoAction({
        sub,
        email,
        username,
        permissions: permissionsJson,
      });
      if (icao === null) {
        if (permissionsJson.length > 1) {
          let icaoList = [];
          for (let i = 0; i < permissionsJson.length; i++) {
            let permissionItem = permissionsJson[i];
            if (permissionItem != null && permissionItem.company != null && permissionItem.company.icao != null) {
              icaoList.push(permissionItem.company.icao);
            }
          }
          this.setState({
            icaoList,
            showModalICAO: true,
            icao: icaoList[0]
          });
        } else {
          if (permissionsJson[0] === null || permissionsJson[0].company === null || permissionsJson[0].company.icao === null) {
            message.error("Something went wrong.");
          } else {
            localStorage.setItem('icao', permissionsJson[0].company.icao);
            window.location.reload();
          }
        }
      } else {
        let showDSLink = false;
        let showSLLink = false;
        for (let i = 0; i < permissionsJson.length; i++) {
          let permissionItem = permissionsJson[i];
          if (permissionItem != null && permissionItem.company != null && permissionItem.company.icao != null && permissionItem.company.icao === icao) {
            for (let j = 0; j < permissionItem.permissions.length; j++) {
              let roleItem = permissionItem.permissions[j];
              if (roleItem.code != null) {
                if (roleItem.code === 'REQUEST' || roleItem.code === 'LINKS' || roleItem.code === 'DOWNLOAD') {
                  showDSLink = true;
                }
                if (roleItem.code === 'REQUESTSL' || roleItem.code === 'LINKSSL' || roleItem.code === 'DOWNLOADSL') {
                  showSLLink = true;
                }
              }
            }
          }
        }
        this.setState({
          showDSLink,
          showSLLink,
        });
        this.props.setICAOAction(icao);
      }
    }
  }

  // toggle = () => {
  //   this.setState({
  //     collapsed: !this.state.collapsed,
  //   });
  // };

  setICAO() {
    if (this.state.icao != null) {
      localStorage.setItem('icao', this.state.icao);
      window.location = "/SDE";
    }
  }

  render() {
    let { access_token, loading, email, permissions } = this.props;
    // let { showModalICAO, collapsed, icaoList, icao, showDSLink, showSLLink } = this.state;
    let { showModalICAO, icaoList, icao, showDSLink, showSLLink } = this.state;
    return (
      <Layout style={{ minHeight: '100vh' }}>
        {loading === true &&
          <div className="loading-container">
            <Spin size="large" />
          </div>
        }
        {(access_token != null && email != null) &&
          // <Sider collapsed={collapsed} trigger={null} collapsible>
          <Sider trigger={null}>
            <div className="logo">
              <div
                onClick={() => window.location = "/SDE"}
                style={{
                  float: 'left',
                  // display: collapsed ? 'none' : 'block',
                }}>
                <img src="/logo.jpg" alt="SDE" />
              </div>
            </div>
            <Menu theme="dark" selectedKeys={[localStorage.getItem('selectedTab')]} mode="inline">
              {showDSLink === true &&
                <Menu.Item key="MDE">
                  <span onClick={() => window.location = "/SDE/ExportDS"}>Datasets</span>
                </Menu.Item>
              }
              {showSLLink === true &&
                <Menu.Item key="SL">
                  <span onClick={() => window.location = "/SDE/ExportSL"}>FOMAX Security Logs</span>
                </Menu.Item>
              }
            </Menu>
          </Sider>
        }
        <Layout style={{ backgroundColor: 'white' }}>
          {(access_token != null && email != null) &&
            <Header style={{ background: '#141925', padding: 0, height: '55px', lineHeight: '55px' }}>
              <Dropdown placement="bottomRight" overlayClassName="user-menu" className="user-container" overlay={
                <Menu>
                  {permissions != null && permissions.length > 1 &&
                    <Menu.Item>
                      <a onClick={() => {
                        localStorage.removeItem('icao');
                        window.location.reload();
                      }}>
                        Switch ICAO
                      </a>
                    </Menu.Item>
                  }
                  <Menu.Item>
                    <a onClick={() => this.props.logoutAction()}>
                      Logout
                    </a>
                  </Menu.Item>
                </Menu>
              } trigger={['click']}>
                <Button><Icon type="user" /> {email}</Button>
              </Dropdown>
          </Header>
          }
          <Content style={{ margin: '0 16px' }}>
            <Router>
              <Switch>
                <Route path="/" exact component={Root} />
                <PrivateRoute path="/SDE" exact component={Home} />
                <PrivateRoute path="/SDE/ExportDS" exact component={MDE} />
                <PrivateRoute path="/SDE/ExportSL" exact component={SecurityLog} />
              </Switch>
            </Router>
          </Content>
        </Layout>
        <Modal
          title="Please choose ICAO to apply properly rights"
          visible={showModalICAO}
          closable={false}
          onOk={() => this.setICAO()}
          cancelButtonProps={{ style: { display: 'none' } }}>
          {icaoList.length > 0 &&
            <Radio.Group value={icao} buttonStyle="solid" onChange={(e) => this.setState({ icao: e.target.value })}>
              {icaoList.map((item) =>
                <Radio.Button style={{ width: 100, textAlign: 'center' }} value={item}>{item}</Radio.Button>
              )}
            </Radio.Group>
          }
        </Modal>
        <BackTop />
      </Layout>
    )
  }
}

function mapStateToProps(state) {
  return {
    access_token: state.global.access_token,
    permissions: state.global.permissions,
    loading: state.global.loading,
    email: state.global.email,
  }
}

export default connect(mapStateToProps, { loginAction, logoutAction, setInfoAction, setICAOAction })(App)
