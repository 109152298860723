const prefix = 'GLOBAL/';

export const LOADING = prefix + 'LOADING';
export const STOP_LOADING = prefix + 'STOP_LOADING';
export const ACTIVE_TAB = prefix + 'ACTIVE_TAB';
export const LOGIN = prefix + 'LOGIN';
export const LOGIN_OAUTH = prefix + 'LOGIN_OAUTH';
export const LOGOUT = prefix + 'LOGOUT';
export const LOGOUT_OAUTH = prefix + 'LOGOUT_OAUTH';
export const SET_INFO = prefix + 'SET_INFO';
export const SET_ICAO = prefix + 'SET_ICAO';