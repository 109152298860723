import React from 'react';
import { connect } from 'react-redux';
import { Typography, Steps, Icon, message } from 'antd';
import { loginAction, setInfoAction, toggleLoadingAction, logoutAction } from '../../actions/global';
import { DOMAIN, CLIENT_ID, CLIENT_SECRET, REDIRECT_URI, SAS_URL, SAS_ENV, X_API_KEY, SAS_X_API_KEY } from '../../config/global';
import axios from 'axios';
import queryString from 'query-string';
const { Title, Paragraph } = Typography;
const { Step } = Steps;

class Home extends React.Component {
  componentDidMount() {
    localStorage.removeItem('selectedTab');
    let params = queryString.parse(this.props.location.search)
    let sub = null;
    let email = null;
    let username = null;
    if (params.code != null) {
      return this.props.toggleLoadingAction(true)
        .then(() => {
          return axios({
            method: 'post',
            headers: { 'x-api-key': `${X_API_KEY}` },
            url: `${DOMAIN}/oauth2/token`,
            data: queryString.stringify({
              grant_type: "authorization_code",
              client_id: CLIENT_ID,
              code: params.code,
              scope: 'profile',
              redirect_uri: REDIRECT_URI,
            }),
            auth: {
              username: CLIENT_ID,
              password: CLIENT_SECRET,
            },
          });
        }).then(result => {
          let { access_token, expires_in, id_token, refresh_token, token_type } = result.data;
          expires_in = expires_in * 1000 + Date.now();
          return this.props.loginAction({
            access_token,
            expires_in,
            id_token,
            refresh_token,
            token_type
          })
        }).then((result) => {
          return axios({
            method: 'get',
            url: `${DOMAIN}/oauth2/userInfo`,
            headers: {
              Authorization: `Bearer ${result.access_token}`,
              'x-api-key': `${X_API_KEY}`
            }
          });
        }).then((result) => {
          sub = result.data.sub;
          email = result.data.email;
          username = result.data.username;
          return axios({
            method: 'get',
            headers: { 'x-api-key': `${SAS_X_API_KEY}` },
            url: `${SAS_URL}/mde/od/permissions?appCode=MDE&stage=${SAS_ENV}&version=1.0&login=${email}`,
          })
        }).then((result) => {
          let { authorizations } = result.data;
          if (authorizations.length === 0) {
            return message.error("You are not authorized to access. Please contact Skywise support team.", 3, () => {
              this.props.logoutAction();
            });
          } else {
            let permissionsResult = [];
            for (let i = 0; i < authorizations.length; i++) {
              let addNew = true;
              for (let j = 0; j < permissionsResult.length; j++) {
                if (permissionsResult[j] != null && permissionsResult[j].permissions && permissionsResult[j].company != null && permissionsResult[j].company.icao != null && authorizations[i] != null && authorizations[i].permissions && authorizations[i].company != null && authorizations[i].company.icao != null &&
                  permissionsResult[j].company.icao === authorizations[i].company.icao
                ) {
                  addNew = false;
                  for (let z = 0; z < authorizations[i].permissions.length; z++) {
                    if (!permissionsResult[j].permissions.includes(authorizations[i].permissions[z])) {
                      permissionsResult[j].permissions.push(authorizations[i].permissions[z]);
                    }
                  }
                  break;
                }
              }
              if (addNew === true) {
                permissionsResult.push(authorizations[i]);
              }
            }
            return this.props.setInfoAction({
              sub,
              email,
              username,
              permissions: permissionsResult
            });
          }
        }).then(() => {
          window.location = '/';
        }).catch((error) => {
          this.props.toggleLoadingAction(false);
          message.error(JSON.stringify(error));
        });
    }
  }

  render() {
    return (
      <Typography style={{ marginTop: 20 }}>
        <Title>Introduction</Title>
        <Paragraph>
          The Special Data Export tool (SDE) allows authorized user to export a copy of data from Foundry.
        </Paragraph>
        <Title>Export process</Title>
        <Steps>
          <Step status="finish" title="Login" icon={<Icon type="user" />} />
          <Step status="finish" title="Make Download Request" icon={<Icon type="solution" style={{ color: '#EFBA2C' }} />} />
          <Step status="finish" title="Waiting Approve *" icon={<Icon type="solution" style={{ color: '#D82C2D' }} />} />
          <Step status="finish" title="Get Download Link" icon={<Icon type="download" style={{ color: '#1697A6' }} />} />
          <Step status="finish" title="Done" icon={<Icon type="smile-o" />} />
        </Steps>
          <div style={{ textAlign: 'center', marginTop: 55, color: '#C7BBC7', fontSize: 16 }}>In case of question or issue, please contact Skywise Support</div>
          <div style={{ textAlign: 'center', color: '#C7BBC7', fontSize: 16 }}>* only for Datasets export feature</div>
      </Typography>
    )
  }
}
function mapStateToProps(state) {
  return {
    access_token: state.global.access_token,
  }
}

export default connect(mapStateToProps, { loginAction, logoutAction, setInfoAction, toggleLoadingAction })(Home)